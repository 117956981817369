/* document, Homebox */
import React from 'react';
import { Helmet } from 'react-helmet';

export default class ComparisonScripts extends React.Component {
  // As we load a script async, we need to keep pinging until
  // it is fully loaded.
  renderComparison(options) {
    if (typeof Homebox !== 'undefined') { /* eslint-disable-line */
      Homebox.renderApp(options) /* eslint-disable-line */
    } else {
      let counter = 0;
      setTimeout(() => {
        counter++;
        if (counter < 50) {
          this.renderComparison(options);
        }
      }, 200);
    }
  }

  componentDidMount() {
    let options = { serviceType: 'energy', comparisonStyle: 'quick' }
    if (this.props.options) {
      options = this.props.options;
    }
    this.renderComparison(options);
  }

  // Note: when running locally, the comparison app bundle must be 
  // served in production mode. In dev mode Homebox{} is not exposed.
  render() {
    if (process.env.GATSBY_ACTIVE_ENV === 'production') {
      return (
        <Helmet>
          <script async defer src="https://comparison.homebox.co.uk/js/homebox-v2.js"></script>
        </Helmet>
      )
    } else {
      return (
        <Helmet>
          {/* <script async defer src="https://comparison.homebox.co.uk/js/homebox-v2.js"></script> */}
          <script async defer src="http://localhost:3012/js/homebox-v2.js"></script>
        </Helmet>
      )
    }
  }
}

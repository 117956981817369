/* window */
import React from 'react'
// import logger from '../utilities/logger';
// import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';
// import HomepageV2A from '../spaces/Homepage/HomepageV2A';
import HomepageV2B from '../spaces/Homepage/HomepageV2B';

export default class SiteIndex extends React.Component {
  constructor(props) {
    super(props);
    // emitter.defineVariants('exp_movein_journey_1', ['A', 'B']);

    // emitter.addPlayListener((experimentName, variantName) => {
    //   // Push to Amplitude.
    //   logger.identify(
    //     null,
    //     { [experimentName]: variantName }
    //   );
    //   logger.track(`Started experiment ${experimentName}`);
    // });

  }

  render() {
    // HomepageV2B won the experiment.
    return (
      <HomepageV2B />
    )
    // return (
    //   <Experiment name="exp_movein_journey_1">
    //     <Variant name="A">
    //       <HomepageV2A />
    //     </Variant>
    //     <Variant name="B">
    //       <HomepageV2B />
    //     </Variant>
    //   </Experiment>
    // )
  }
}

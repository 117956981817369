/* window */
import React from 'react'
import Siema from 'siema';
import { FaAngleRight, FaCheck, FaTimes } from 'react-icons/fa';
import ComparisonScripts from '../../components/ComparisonScripts';
// import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';

import Layout from '../../components/Layout';

// eslint-disable-next-line
import leadPage from '../../components/leadPage.css';
// eslint-disable-next-line
import hero from '../../components/hero.css';
// eslint-disable-next-line
import buttons from '../../components/Buttons/buttons.css';

export default class HomepageV2B extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 0,
    }

    // emitter.defineVariants('exp_movein_journey_1', ['A', 'B']);
    // Fore a re-render, see this issue: https://github.com/gatsbyjs/gatsby/issues/17914
    this.state = { isClient: false }
  }

  componentDidMount() {
    this.siema = new Siema();
    this.setState({ isClient: true })
  }

  goto = (num) => {
    this.siema.goTo(num);
  }

  buttonClass = (num) => {
    return `home-features__link-item btn0 active`;
  }

  render() {
    return (
      <Layout navBar={ { position: 'floating' } }>
        <div>
          <div className="hero__overlay">
            <div className="container-standard">
              <div className="hero__wrapper">
                <div className="hero__intro">
                  <h1 className="hero__title">Set Up Your Gas & Electricity Now</h1>
                  <div className="hero__strapline">
                   Set up your gas & electricity without the hassle. Choose from the best energy suppliers and <strong>save £100s</strong>.
                  </div>
                  <div className="hero__primary-image">
                    <img src="/images/hub_iphone_x.png" className="hero__image" alt="iPhone with Homebox energy bill saving app" />
                  </div>
                  <a href="/setup" className="button button--primary hero__cta testb" key="testb">Get Started &nbsp; <FaAngleRight /></a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="logos__wrapper">
          <div className="container-standard">
            <div className="logos">
              <div className="logos__logo logo--text-first">Supported<br /> Suppliers:</div>
              <div className="logos__logo"><img src="/images/suppliers/bulb-logo-small-transparent.png" alt="Bulb Energy" /></div>
              <div className="logos__logo"><img src ="/images/suppliers/bt-small.png" alt="PlusNet Broadband" /></div>
              <div className="logos__logo"><img src="/images/suppliers/ovo-small.png" alt="OVO Energy" /></div>
              <div className="logos__logo"><img src="/images/suppliers/talktalk-small.png" alt="Talk Talk Broadband" /></div>
              <div className="logos__logo"><img src="/images/suppliers/edf-small.png" alt="EDF Energy"/></div>
              <div className="logos__logo"><img src="/images/suppliers/sky-small.png" alt="Sky Broadband"/></div>
              <div className="logos__logo logo--text-last">+ Many More</div>
            </div>
          </div>
        </div>

        <div className="angledBack" style={{ position: 'relative'}}>

          <div className="hiw-v2 " id="hiw">
            <div className="container-standard">
              <div className="hiw-v2__items">
                <div className="grid-medium-up">
                  <div className="hiw-v2__item grid-item">
                    <div className="hiw-v2__item-no">1</div>
                    <h4>Add your details</h4>
                    <p>Your details are securely stored so you won't need to enter them again in the future.</p>
                  </div>
                  <div className="hiw-v2__item grid-item">
                    <div className="hiw-v2__item-no">2</div>
                    <h4>Get the best deal for you</h4>
                    {/* <p>Homebox intelligently finds you the best deals from <strong>a wider range of deals than traditional comparison sites.</strong></p> */}
                    <p>Homebox intelligently finds you the best deals from a range of energy suppliers</p>
                    {/*<p><a href="">How do we beat comparison sites? <FaAngleRight /></a></p>*/}
                  </div>
                  <div className="hiw-v2__item grid-item">
                    <div className="hiw-v2__item-no">3</div>
                    <h4>Switch without the hassle</h4>
                    <p>We take the effort out of finding and staying on the best energy deals <span role="img" aria-label="rocket">🚀</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="savings" id="savings">
            <div className="container-thin">
              <h2 className="savings__title section__title">Get Set Up Now</h2>
              <p className="savings__strapline">Setting up your energy supply is important. Get a great deal quickly with Homebox.</p>
              <center><a href="/setup" className="button button--primary hero__cta">Get Started &nbsp; <FaAngleRight /></a></center>
            </div>
          </div>

          <div className="full-picture">
            <div className="container-thin">

              <div className="full-picture__heading">
                <h2 className="full-picture__title section__title">What's Different About Homebox?</h2>
              </div>

              <div className="full-picture__simple-comp">

                <div className="full-picture__first">
                  <h3 className="full-picture__simple-comp-title">Homebox</h3>
                  <ul className="full-picture__simple-comp-items">
                    {/* <li className="full-picture__simple-comp-item"><FaCheck />Scans a huge range of deals</li> */}
                    <li className="full-picture__simple-comp-item"><FaCheck />Quick no-hassle set up</li>
                    <li className="full-picture__simple-comp-item"><FaCheck />Independent supplier ratings</li>
                    <li className="full-picture__simple-comp-item"><FaCheck />Intelligently matches deals to your preferences, renewable, low-cost or smart tech</li>
                  </ul>
                </div>

                <div className="full-picture__second">
                  <h3 className="full-picture__simple-comp-title">Traditional Comparison Sites</h3>
                  <ul className="full-picture__simple-comp-items">
                    <li className="full-picture__simple-comp-item">
                      <FaTimes />Focus on deals they earn money from
                    </li>
                    {/* <li className="full-picture__simple-comp-item">
                      <FaTimes />Switch to a small range of suppliers
                    </li> */}
                    <li className="full-picture__simple-comp-item">
                      <FaTimes />Basic price monitoring
                    </li>
                    <li className="full-picture__simple-comp-item">
                      <FaTimes />Only 38% of people trust traditional comparison sites
                    </li>
                  </ul>
                </div>

              </div>

              <div>
                <img src="/images/piggy.jpg" className="full-picture__image" alt="Piggy bank for saving money" />
              </div>

            </div>
          </div>

        </div>

        <div className="savings" id="savings">
          <div className="container-standard">
            <div className="grid-medium-up">

              <div className="savings__story">
                <img className="savings__pic" src="/images/customer-alex.png" alt="Alex - a happy Homebox customer" />
                <div className="savings__name">Alexander Bilney</div>
                <div className="savings__quote">"I saved money on both my <strong>electricity and internet</strong> in no more than 10-15 mins. <strong>I thoroughly recommend Homebox.</strong>"</div>
                <div className="savings__tp">
                  <a href="https://www.trustpilot.com/reviews/5a01e6a6ee693f08c8202a72" rel="noopener noreferrer" target="_blank">
                    <img src="/images/trustpilot-stars-1.png" alt="Homebox 5 star reviews" />
                  </a>
                </div>
              </div>

              <div className="savings__story">
                <img className="savings__pic" src="/images/testimonial-paul.jpg" alt="Alex a happy Homebox customer" />
                <div className="savings__name">Paul Haydon</div>
                <div className="savings__quote">"Brilliant, very easy and straightforward to set up. Got a few emails over the months and clicked to save, haven't had to do anything and know I'm always on a decent deal!"</div>
                <div className="savings__tp">
                  <a href="https://www.trustpilot.com/review/homebox.io" rel="noopener noreferrer" target="_blank">
                    <img src="/images/trustpilot-stars-1.png" alt="Homebox 5 star reviews" />
                  </a>
                </div>
              </div>

            </div>
            {/* <div style={{ textAlign: 'center', marginTop: '3rem' }}>
              <a href="https://www.trustpilot.com/review/homebox.io" target="_blank" rel="noopener noreferrer" className="button button--secondary">
                More Reviews &nbsp;<FaAngleRight />
              </a>
            </div> */}
          </div>
        </div>

        <div>
          <div className="savings savingsCta" key="setup-2">
            <div className="container-thin">
              <h2 className="savings__title section__title">Get Set Up Now</h2>
              <p className="savings__strapline">Setting up your energy supply is important. Get a great deal quickly with Homebox.</p>
              <center><a href="/setup" className="button button--primary hero__cta">Get Started &nbsp; <FaAngleRight /></a></center>
            </div>
          </div>
        </div>

        <div className="home-features">

          <div className="home-features__nav">
            <div className="container-thin">
              <h2 className="section__title">What You Get</h2>
              {/*<p>100% Free<p>*/}
              <div className="home-features__links">
                <div className="home-features__link-item btn0 active" onClick={() => this.goto(0)} role="button" onKeyPress={() => this.goto(0)} tabIndex={-4}>Your Hub</div>
                <div className="home-features__link-item btn1" onClick={() => this.goto(1)} role="button" onKeyPress={() => this.goto(1)} tabIndex={-3}>Price Monitoring</div>
                <div className="home-features__link-item btn2" onClick={() => this.goto(2)} role="button" onKeyPress={() => this.goto(2)} tabIndex={-2}>Financial Insight</div>
                {/*<div className="home-features__link-item btn3">Auto-Switching</div>*/}
                <div className="home-features__link-item btn4" onClick={() => this.goto(4)} role="button" onKeyPress={() => this.goto(4)} tabIndex={-1}>Security</div>
              </div>
            </div>
          </div>

          <div className="siema">

            <div>
              <div className="container-thin">
                <div className="grid-medium-up">
                  <div className="grid-item">
                    <h3 className="home-features__title">All Your Bills, All In One Place</h3>
                    <p>At last - one place to get a quick overview of all your home services. Let Homebox look after your bills.</p>
                    <p>To help you find and stay on the best deals you'll need to enter accurate information, such as how much you're paying per month. Login to Homebox and update your details any time.</p>
                    <p>We're constantly improving the Homebox Hub, we want it to become the control centre for all your home admin.</p>
                    {/* <div className="home-features__cta">
                      <a href="https://hub.homebox.co.uk?mode=signUp" className="button button--primary button--large analytics-signUp">Get Started &nbsp;<FaAngleRight /></a>
                    </div> */}
                  </div>
                  <div className="grid-item">
                    <img src="/images/how-it-works-1.png" alt="Adding your current energy account" className="hiw-v2__item-side-image" />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="container-thin">
                <div className="grid-medium-up">
                  <div className="grid-item">
                    <h3 className="home-features__title">Always Be On The Best Deals</h3>
                    <p>We automatically scan for better deals for energy & broadband, constantly. We take into account things like upcoming price changes, how long until your contract ends and seasonal changes.</p>
                    <p>Our energy comparison system has been built from the ground up, for whole of market gas & electricity comparisons and switching.</p>
                    <p>You're in control. By default we send alerts via email based on your customisable price alert threshold. Turn on energy autoswitching if it suits you & let us flip you to the best deal automatically.</p>
                    {/* <div className="home-features__cta">
                      <a href="https://hub.homebox.co.uk?mode=signUp" className="button button--primary button--large analytics-signUp">Get Started &nbsp;<FaAngleRight /></a>
                    </div> */}
                  </div>
                  <div className="grid-item">
                    <img src="/images/how-it-works-2.png" alt="Homebox.io monthly household bills management" className="hiw-v2__item-side-image" />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="container-thin">
                <div className="grid-medium-up">
                  <div className="grid-item">
                    <h3 className="home-features__title">Understand Your Home Finances</h3>
                    <p>Just 1 in 3 people in the UK say they know exactly how much their household bill are per month.</p>
                    <p>Homebox gives you one place to see an overview of all your household bills. And we're building some exciting features to help you stay in control.</p>
                    {/* <div className="home-features__cta">
                      <a href="https://hub.homebox.co.uk?mode=signUp" className="button button--primary button--large analytics-signUp">Get Started &nbsp;<FaAngleRight /></a>
                    </div> */}
                  </div>
                  <div className="grid-item">
                    {/*<img src="/images/how-it-works-2.png" alt="Homebox.io monthly household bills management" className="hiw-v2__item-side-image">*/}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="container-thin">
                <h3 className="home-features__title">Auto-Switching</h3>
                <p></p>
                <img src="/images/how-it-works-3.png" alt="Homebox switched to cheap energy deal" className="hiw-v2__item-side-image" />
              </div>
            </div>

            <div>
              <div className="container-thin">
                <div className="grid-medium-up">
                  <div className="grid-item">
                    <h3 className="home-features__title">Fully secure</h3>
                    <p>Homebox is built and maintained by a team of engineers who have decades of experience working with major worldwide brands.</p>
                    <p>Unlike other services, there's no requirement to enter the usernames or passwords for your bank (via Open Banking) or energy supplier accounts.</p>
                    <p>Everything has ben built from the ground up to modern security standards, using 256-bit AES encryption everywhere.</p>
                    {/* <div className="home-features__cta">
                      <a href="https://hub.homebox.co.uk?mode=signUp" className="button button--primary button--large analytics-signUp">Get Started &nbsp;<FaAngleRight /></a>
                    </div> */}
                  </div>
                  <div className="grid-item">
                    {/*<img src="/images/how-it-works-2.png" alt="Homebox.io monthly household bills management" className="hiw-v2__item-side-image" />*/}
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </Layout>
    )
  }
}
